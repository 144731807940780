import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getNetSales } from '../services/user'

const initialState = {

}

export const statebenchmarking = createSlice({
    name: 'statebenchmarking',
    initialState,
    reducers: {
    },
    
  })

  export default statebenchmarking.reducer