import React, { useEffect, useState } from "react";
import TopNav from "../components/common/topnav.component";
import { ScrollPanel } from "primereact/scrollpanel";
import ReactEcharts from "echarts-for-react";

export default function StateBenchMarking(props) {

    const [activeTab,setActiveTab] = useState(0);

    const option = {
        xAxis: {
            max: 100,
            show: false,  
        },
        yAxis: {
            type: 'category',
            show: false,  
        },
        grid: {
            height: 30, 
            left: 0,
            right: 0,
            top: '30%',
        },
        series: [
            {
                type: 'bar',
                data: [87], 
                showBackground: true,
                backgroundStyle: {
                    color: '#3e4b68',  
                    borderRadius: 100,
                },
                itemStyle: {
                    color: '#657cd7',  
                    borderRadius: 100,
                },
                barWidth: '100%',
                label: {
                    show: true,
                    position: 'insideleft',
                    padding : [8,0,0,15],
                    formatter: '{c}%',
                    color: '#fff', 
                    fontSize: 16,
                    // fontWeight: 'bold',
                },
            },
        ],
    };
    

    return (
        <>
            <div className='body'>
                <TopNav pagename="State Benchmarking" pagenamedescription="" setUserData={props.setUserData} />
                <div className="px-[30px] 3xl:px-[1.563vw] py-[18px] 3xl:py-[0.938vw]">
                    <ScrollPanel className="h-[460px] xl:h-[460px] 2xl:h-[650px] 3xl:h-[45vw]">
                        <div className=" grid grid-cols-12  gap-[16px] xl:gap-[16px] 3xl:gap-[0.833vw]">
                            <div className=" col-span-4  p-[16px] xl:p-[16px] 3xl:p-[0.833vw]  bg-[#192D5C] rounded-[8px]">
                                <div className="flex justify-between items-center">
                                    <div>
                                        <div class="text-[#fff] text-[18px] xl:text-[18px] 3xl:text-[0.938vw] font-medium">Overall Score</div>
                                        <div class="text-[#fff] text-[30px] xl:text-[32px] 3xl:text-[1.667vw]">8/10</div>
                                    </div>
                                    <div class="h-[60px] w-[250px] xl:w-[250px] 2xl:w-[20.833vw] 3xl:w-[20.833vw]">
                                        <ReactEcharts option={option} style={{ position: 'relative', width: '100%', height: '60px', padding: '0px', margin: '0px', borderWidth: '0px', cursor: 'pointer' }} />
                                    </div>
                                </div>
                                <div className=" text-[#fff] text-[16px] xl:text-[16px] 3xl:text-[0.938vw] mt-[12px] xl:mt-[12px] 3xl:mt-[0.625vw]">Partially meeting targets</div>
                            </div>
                            <div className=" col-span-4  p-[16px] xl:p-[16px] 3xl:p-[0.833vw]  bg-[#1a2025b3] rounded-[8px]">
                                <div className="flex items-start  gap-[8px] xl:gap-[8px] 3xl:gap-[0.417vw]">
                                    <div className=" mr-2"><i className="pi pi-info-circle text-white"></i></div>
                                    <div className="text-[#fff] text-[16px] xl:text-[16px] 3xl:text-[0.938vw]">Clay county exceeds expectation on Student Achievement and Graduation with scores of 8</div>
                                </div>
                            </div>
                            <div className=" col-span-4  p-[16px] xl:p-[16px] 3xl:p-[0.833vw]  bg-[#1a2025b3] rounded-[8px]">
                                <div className=" flex items-start  gap-[8px] xl:gap-[8px] 3xl:gap-[0.417vw]">
                                    <div className=" mr-2"><i className="pi pi-info-circle text-white"></i></div>
                                    <div className=" text-[#fff] text-[16px] xl:text-[16px] 3xl:text-[0.938vw]">Areas of Improvement include Student growth and Attendance</div>
                                </div>
                            </div>
                        </div>
                        <div className=" mt-[24px] 3xl:mt-[1.25vw]">
                            <div className="flex items-center gap-[16px] 3xl:gap-[0.833vw]">
                                <button class={` ${activeTab == 0 ? 'bg-[#FFF] text-black' : ' bg-[#1C2732] text-[#FFFFFF]'}  text-[14px] 3xl:text-[0.833vw] font-normal rounded-full px-[14px] 3xl:px-[0.833vw] py-[10px] 3xl:py-[0.625vw]`} onClick={()=> setActiveTab(0)}>District size</button>
                                <button class={` ${activeTab == 1 ? 'bg-[#FFF] text-black' : ' bg-[#1C2732] text-[#FFFFFF]'} text-[14px] 3xl:text-[0.833vw] font-normal rounded-full px-[14px] 3xl:px-[0.833vw] py-[10px] 3xl:py-[0.625vw]`} onClick={()=> setActiveTab(1)}>Neighboring districts</button>
                                <button class={`${activeTab == 2 ? 'bg-[#FFF] text-black' : ' bg-[#1C2732] text-[#FFFFFF]'} text-[#FFFFFF] text-[14px] 3xl:text-[0.833vw] font-normal rounded-full px-[14px] 3xl:px-[0.833vw] py-[10px] 3xl:py-[0.625vw]`} onClick={()=> setActiveTab(2)}>Demographic</button>
                                <button class={`${activeTab == 3 ? 'bg-[#FFF] text-black' : ' bg-[#1C2732] text-[#FFFFFF]'} text-[#FFFFFF] text-[14px] 3xl:text-[0.833vw] font-normal rounded-full px-[14px] 3xl:px-[0.833vw] py-[10px] 3xl:py-[0.625vw]`} onClick={()=> setActiveTab(3)}>Top performers</button>
                                <button class={`${activeTab == 4 ? 'bg-[#FFF] text-black' : ' bg-[#1C2732] text-[#FFFFFF]'} text-[#FFFFFF] text-[14px] 3xl:text-[0.833vw] font-normal rounded-full px-[14px] 3xl:px-[0.833vw] py-[10px] 3xl:py-[0.625vw]`} onClick={()=> setActiveTab(4)}>Funding level</button>
                                <button class={`${activeTab == 5 ? 'bg-[#FFF] text-black' : ' bg-[#1C2732] text-[#FFFFFF]'} text-[#FFFFFF] text-[14px] 3xl:text-[0.833vw] font-normal rounded-full px-[14px] 3xl:px-[0.833vw] py-[10px] 3xl:py-[0.625vw]`} onClick={()=> setActiveTab(5)}>Cost per student</button>
                            </div>
                        </div>
                        <div className=" mt-[25px] 3xl:mt-[1.263vw] mb-5">
                            <div className=" rounded-[8px] border border-[rgba(255,255,255,0.10)] bg-[rgba(0,35,64,0.70)] p-[20px] 3xl:p-[1.042vw]">
                                <div className=" space-y-[6px]">
                                    <div className="grid grid-cols-12 gap-[18px] 2xl:gap-[56px] 3xl:gap-[2.917vw] px-[22px] 3xl:px-[1.25vw] py-[4px] ">
                                        <div className=" col-span-2"></div>
                                        <div class="col-span-2"><div class="text-[#fff] text-[20px] 3xl:text-[1.042vw] font-medium leading-[140%] mb-[4px]">Clay Country</div><div class="text-[#fff] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">EB students constitute 40 of the population</div></div>
                                        <div class="col-span-2"><div class="text-[#fff] text-[20px] 3xl:text-[1.042vw] font-medium leading-[140%] mb-[4px]">State score</div><div class="text-[#fff] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">Fl and studerct with disasiny constitute 40% of the population</div></div>
                                        <div class="col-span-2"><div class="text-[#fff] text-[20px] 3xl:text-[1.042vw] font-medium leading-[140%] mb-[4px]">District 2</div><div class="text-[#fff] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">EB thuciens constitute 40% of the population</div></div>
                                        <div class="col-span-2"><div class="text-[#fff] text-[20px] 3xl:text-[1.042vw] font-medium leading-[140%] mb-[4px]">District 3</div><div class="text-[#fff] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">EB students constitute son of the popcizion</div></div>
                                        <div class="col-span-2"><div class="text-[#fff] text-[20px] 3xl:text-[1.042vw] font-medium leading-[140%] mb-[4px]">District 4</div><div class="text-[#fff] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">El and students with drunty constmube 40% of the population</div></div>
                                    </div>
                                    <div className="grid grid-cols-12 gap-[18px] 2xl:gap-[56px] 3xl:gap-[2.917vw] px-[22px] 3xl:px-[1.25vw] py-[12px] 3xl:py-[0.625vw] bg-[#0C1A26]">
                                        <div class="col-span-2"><div class="text-[#fff] text-[18px] 3xl:text-[0.938vw] font-medium leading-[140%] mb-[4px]">Achievement</div><div class="text-[#C9D0DB] text-[14px] 3xl:text-[0.833vw] font-normal leading-[140%]">Scones ELA Math and Science</div></div>
                                        <div class="col-span-2"><div class="flex items-center gap-[6px]"><div class="bg-[#069564] h-[12px] 3xl:h-[0.625vw] w-[12px] 3xl:w-[0.625vw] rounded-full mt-[2px]"></div><div class="text-[#fff] text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] ">8</div></div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">Rank : 14</div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">PY : 7</div></div>
                                        <div class="col-span-2"><div class="flex items-center gap-[6px]"><div class="bg-[#069564] h-[12px] 3xl:h-[0.625vw] w-[12px] 3xl:w-[0.625vw] rounded-full mt-[2px]"></div><div class="text-[#fff] text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] ">8</div></div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">Rank : 14</div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">PY : 7</div></div>
                                        <div class="col-span-2"><div class="flex items-center gap-[6px]"><div class="bg-[#069564] h-[12px] 3xl:h-[0.625vw] w-[12px] 3xl:w-[0.625vw] rounded-full mt-[2px]"></div><div class="text-[#fff] text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] ">8</div></div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">Rank : 14</div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">PY : 7</div></div>
                                        <div class="col-span-2"><div class="flex items-center gap-[6px]"><div class="bg-[#069564] h-[12px] 3xl:h-[0.625vw] w-[12px] 3xl:w-[0.625vw] rounded-full mt-[2px]"></div><div class="text-[#fff] text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] ">8</div></div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">Rank : 14</div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">PY : 7</div></div>
                                        <div className="col-span-2 relative">
                                            <div class="flex items-center gap-[6px]"><div class="bg-[#069564] h-[12px] 3xl:h-[0.625vw] w-[12px] 3xl:w-[0.625vw] rounded-full mt-[2px]"></div><div class="text-[#fff] text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] ">8</div></div>
                                            <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">Rank : 14</div>
                                            <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">PY : 7</div>
                                            <div className="absolute top-0 right-0 cursor-pointer">
                                               <i className="pi pi-chevron-circle-down text-white"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" grid grid-cols-12 gap-[18px] 2xl:gap-[56px] 3xl:gap-[2.917vw] px-[22px] 3xl:px-[1.25vw] py-[12px] 3xl:py-[0.625vw] bg-[#0C1A26]">
                                        <div class="col-span-2"><div class="text-[#fff] text-[18px] 3xl:text-[0.938vw] font-medium leading-[140%] mb-[4px]">Student Growth</div><div class="text-[#C9D0DB] text-[14px] 3xl:text-[0.833vw] font-normal leading-[140%]">Learing game in ELA and Math performw</div></div>
                                        <div class="col-span-2"><div class="flex items-center gap-[6px]"><div class="bg-[#D74210] h-[12px] 3xl:h-[0.625vw] w-[12px] 3xl:w-[0.625vw] rounded-full mt-[2px]"></div><div class="text-[#fff] text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] ">5</div></div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">Rank : 14</div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">PY : 7</div></div>
                                        <div class="col-span-2"><div class="flex items-center gap-[6px]"><div class="bg-[#D74210] h-[12px] 3xl:h-[0.625vw] w-[12px] 3xl:w-[0.625vw] rounded-full mt-[2px]"></div><div class="text-[#fff] text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] ">5</div></div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">Rank : 14</div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">PY : 7</div></div>
                                        <div class="col-span-2"><div class="flex items-center gap-[6px]"><div class="bg-[#D74210] h-[12px] 3xl:h-[0.625vw] w-[12px] 3xl:w-[0.625vw] rounded-full mt-[2px]"></div><div class="text-[#fff] text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] ">5</div></div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">Rank : 14</div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">PY : 7</div></div>
                                        <div class="col-span-2"><div class="flex items-center gap-[6px]"><div class="bg-[#D74210] h-[12px] 3xl:h-[0.625vw] w-[12px] 3xl:w-[0.625vw] rounded-full mt-[2px]"></div><div class="text-[#fff] text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] ">5</div></div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">Rank : 14</div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">PY : 7</div></div>
                                        <div className=" col-span-2 relative">
                                            <div class="flex items-center gap-[6px]"><div class="bg-[#D74210] h-[12px] 3xl:h-[0.625vw] w-[12px] 3xl:w-[0.625vw] rounded-full mt-[2px]"></div><div class="text-[#fff] text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] ">5</div></div>
                                            <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">Rank : 14</div>
                                            <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">PY : 7</div>
                                            <div className=" absolute top-0 right-0 cursor-pointer">
                                            <i className="pi pi-chevron-circle-down text-white"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" grid grid-cols-12 gap-[18px] 2xl:gap-[56px] 3xl:gap-[2.917vw] px-[22px] 3xl:px-[1.25vw] py-[12px] 3xl:py-[0.625vw] bg-[#0C1A26]">
                                        <div class="col-span-2"><div class="text-[#fff] text-[18px] 3xl:text-[0.938vw] font-medium leading-[140%] mb-[4px]">Graduation</div><div class="text-[#C9D0DB] text-[14px] 3xl:text-[0.833vw] font-normal leading-[140%]">A graduas on rate of 80% is benchmark of sta</div></div>
                                        <div class="col-span-2"><div class="flex items-center gap-[6px]"><div class="bg-[#069564] h-[12px] 3xl:h-[0.625vw] w-[12px] 3xl:w-[0.625vw] rounded-full mt-[2px]"></div><div class="text-[#fff] text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] ">8</div></div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">Rank : 14</div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">PY : 7</div></div>
                                        <div class="col-span-2"><div class="flex items-center gap-[6px]"><div class="bg-[#069564] h-[12px] 3xl:h-[0.625vw] w-[12px] 3xl:w-[0.625vw] rounded-full mt-[2px]"></div><div class="text-[#fff] text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] ">8</div></div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">Rank : 14</div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">PY : 7</div></div>
                                        <div class="col-span-2"><div class="flex items-center gap-[6px]"><div class="bg-[#069564] h-[12px] 3xl:h-[0.625vw] w-[12px] 3xl:w-[0.625vw] rounded-full mt-[2px]"></div><div class="text-[#fff] text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] ">8</div></div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">Rank : 14</div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">PY : 7</div></div>
                                        <div class="col-span-2"><div class="flex items-center gap-[6px]"><div class="bg-[#069564] h-[12px] 3xl:h-[0.625vw] w-[12px] 3xl:w-[0.625vw] rounded-full mt-[2px]"></div><div class="text-[#fff] text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] ">8</div></div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">Rank : 14</div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">PY : 7</div></div>
                                        <div class="col-span-2 relative"><div class="flex items-center gap-[6px]"><div class="bg-[#069564] h-[12px] 3xl:h-[0.625vw] w-[12px] 3xl:w-[0.625vw] rounded-full mt-[2px]"></div><div class="text-[#fff] text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] ">8</div></div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">Rank : 14</div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">PY : 7</div>
                                            <div class="absolute top-0 right-0 cursor-pointer"><i className="pi pi-chevron-circle-down text-white"></i></div></div>
                                    </div>
                                    <div className=" grid grid-cols-12 gap-[18px] 2xl:gap-[56px] 3xl:gap-[2.917vw] px-[22px] 3xl:px-[1.25vw] py-[12px] 3xl:py-[0.625vw] bg-[#0C1A26]">
                                        <div class="col-span-2"><div class="text-[#fff] text-[18px] 3xl:text-[0.938vw] font-medium leading-[140%] mb-[4px]">English Proficiency</div><div class="text-[#C9D0DB] text-[14px] 3xl:text-[0.833vw] font-normal leading-[140%]">Florice targeting 70% proñcience</div></div>
                                        <div class="col-span-2"><div class="flex items-center gap-[6px]"><div class="bg-[#069564] h-[12px] 3xl:h-[0.625vw] w-[12px] 3xl:w-[0.625vw] rounded-full mt-[2px]"></div><div class="text-[#fff] text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] ">8</div></div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">Rank : 14</div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">PY : 7</div></div>
                                        <div class="col-span-2"><div class="flex items-center gap-[6px]"><div class="bg-[#069564] h-[12px] 3xl:h-[0.625vw] w-[12px] 3xl:w-[0.625vw] rounded-full mt-[2px]"></div><div class="text-[#fff] text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] ">8</div></div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">Rank : 14</div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">PY : 7</div></div>
                                        <div class="col-span-2"><div class="flex items-center gap-[6px]"><div class="bg-[#069564] h-[12px] 3xl:h-[0.625vw] w-[12px] 3xl:w-[0.625vw] rounded-full mt-[2px]"></div><div class="text-[#fff] text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] ">8</div></div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">Rank : 14</div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">PY : 7</div></div>
                                        <div class="col-span-2"><div class="flex items-center gap-[6px]"><div class="bg-[#069564] h-[12px] 3xl:h-[0.625vw] w-[12px] 3xl:w-[0.625vw] rounded-full mt-[2px]"></div><div class="text-[#fff] text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] ">8</div></div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">Rank : 14</div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">PY : 7</div></div>
                                        <div class="col-span-2 relative"><div class="flex items-center gap-[6px]"><div class="bg-[#069564] h-[12px] 3xl:h-[0.625vw] w-[12px] 3xl:w-[0.625vw] rounded-full mt-[2px]"></div><div class="text-[#fff] text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] ">8</div></div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">Rank : 14</div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">PY : 7</div>
                                            <div class="absolute top-0 right-0 cursor-pointer"><i className="pi pi-chevron-circle-down text-white"></i></div></div>
                                    </div>
                                    <div className=" grid grid-cols-12 gap-[18px] 2xl:gap-[56px] 3xl:gap-[2.917vw] px-[22px] 3xl:px-[1.25vw] py-[12px] 3xl:py-[0.625vw] bg-[#0C1A26]">
                                        <div class="col-span-2"><div class="text-[#fff] text-[18px] 3xl:text-[0.938vw] font-medium leading-[140%] mb-[4px]">Attendance</div><div class="text-[#C9D0DB] text-[14px] 3xl:text-[0.833vw] font-normal leading-[140%]">Reduction of Chrome absenteeism to less than 10%</div></div>
                                        <div class="col-span-2"><div class="flex items-center gap-[6px]"><div class="bg-[#D74210] h-[12px] 3xl:h-[0.625vw] w-[12px] 3xl:w-[0.625vw] rounded-full mt-[2px]"></div><div class="text-[#fff] text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] ">6</div></div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">Rank : 14</div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">PY : 7</div></div>
                                        <div class="col-span-2"><div class="flex items-center gap-[6px]"><div class="bg-[#D74210] h-[12px] 3xl:h-[0.625vw] w-[12px] 3xl:w-[0.625vw] rounded-full mt-[2px]"></div><div class="text-[#fff] text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] ">6</div></div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">Rank : 14</div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">PY : 7</div></div>
                                        <div class="col-span-2"><div class="flex items-center gap-[6px]"><div class="bg-[#D74210] h-[12px] 3xl:h-[0.625vw] w-[12px] 3xl:w-[0.625vw] rounded-full mt-[2px]"></div><div class="text-[#fff] text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] ">6</div></div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">Rank : 14</div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">PY : 7</div></div>
                                        <div class="col-span-2"><div class="flex items-center gap-[6px]"><div class="bg-[#D74210] h-[12px] 3xl:h-[0.625vw] w-[12px] 3xl:w-[0.625vw] rounded-full mt-[2px]"></div><div class="text-[#fff] text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] ">6</div></div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">Rank : 14</div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">PY : 7</div></div>
                                        <div class="col-span-2 relative"><div class="flex items-center gap-[6px]"><div class="bg-[#D74210] h-[12px] 3xl:h-[0.625vw] w-[12px] 3xl:w-[0.625vw] rounded-full mt-[2px]"></div><div class="text-[#fff] text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] ">6</div></div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">Rank : 14</div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">PY : 7</div><div class="absolute top-0 right-0 cursor-pointer"><i className="pi pi-chevron-circle-down text-white"></i></div></div>
                                    </div>
                                    <div className=" grid grid-cols-12 gap-[18px] 2xl:gap-[56px] 3xl:gap-[2.917vw] px-[22px] 3xl:px-[1.25vw] py-[12px] 3xl:py-[0.625vw] bg-[#0C1A26]">
                                        <div class="col-span-2"><div class="text-[#fff] text-[18px] 3xl:text-[0.938vw] font-medium leading-[140%] mb-[4px]">Advanced Coursework</div><div class="text-[#C9D0DB] text-[14px] 3xl:text-[0.833vw] font-normal leading-[140%]">Encourage more students to take AP, IB tests</div></div>
                                        <div class="col-span-2"><div class="flex items-center gap-[6px]"><div class="bg-[#F2990E] h-[12px] 3xl:h-[0.625vw] w-[12px] 3xl:w-[0.625vw] rounded-full mt-[2px]"></div><div class="text-[#fff] text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] ">7</div></div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">Rank : 14</div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">PY : 7</div></div>
                                        <div class="col-span-2"><div class="flex items-center gap-[6px]"><div class="bg-[#F2990E] h-[12px] 3xl:h-[0.625vw] w-[12px] 3xl:w-[0.625vw] rounded-full mt-[2px]"></div><div class="text-[#fff] text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] ">7</div></div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">Rank : 14</div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">PY : 7</div></div>
                                        <div class="col-span-2"><div class="flex items-center gap-[6px]"><div class="bg-[#F2990E] h-[12px] 3xl:h-[0.625vw] w-[12px] 3xl:w-[0.625vw] rounded-full mt-[2px]"></div><div class="text-[#fff] text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] ">7</div></div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">Rank : 14</div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">PY : 7</div></div>
                                        <div class="col-span-2"><div class="flex items-center gap-[6px]"><div class="bg-[#F2990E] h-[12px] 3xl:h-[0.625vw] w-[12px] 3xl:w-[0.625vw] rounded-full mt-[2px]"></div><div class="text-[#fff] text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] ">7</div></div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">Rank : 14</div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">PY : 7</div></div>
                                        <div class="col-span-2 relative"><div class="flex items-center gap-[6px]"><div class="bg-[#F2990E] h-[12px] 3xl:h-[0.625vw] w-[12px] 3xl:w-[0.625vw] rounded-full mt-[2px]"></div><div class="text-[#fff] text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] ">7</div></div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">Rank : 14</div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">PY : 7</div><div class="absolute top-0 right-0 cursor-pointer"><i className="pi pi-chevron-circle-down text-white"></i></div></div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </ScrollPanel>
                </div>
            </div>
        </>
    )
}



